<template>
  <vx-card style="margin-bottom: 30px">
    <h2>Tracker</h2>
    <vs-row>
      <vs-col class="w-full md:w-1/2 md:pr-5 mb-10">
        <vue-apex-charts
          type="bar"
          height="350"
          :options="timeSpentPlanningActivities"
          :series="timeSpentPlanningActivities.series"
        ></vue-apex-charts>
      </vs-col>
      <vs-col class="w-full md:w-1/2 md:pl-5 mb-10">
        <vue-apex-charts
          type="bar"
          height="350"
          :options="numberOfActivityPlanned"
          :series="numberOfActivityPlanned.series"
        ></vue-apex-charts>
      </vs-col>
      <vs-col class="w-full md:w-1/2 md:pr-5 mb-10 md:mb-0">
        <vue-apex-charts
          type="bar"
          height="350"
          :options="timeSpentActivityReporting"
          :series="timeSpentActivityReporting.series"
        ></vue-apex-charts>
      </vs-col>
      <vs-col class="w-full md:w-1/2 md:pl-5">
        <vue-apex-charts
          type="bar"
          height="350"
          :options="numberOfLearningStoriesCreated"
          :series="numberOfLearningStoriesCreated.series"
        ></vue-apex-charts>
      </vs-col>
    </vs-row>
  </vx-card>
</template>
<script>
import { mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
import Vue from "vue";
import VueLodash from "vue-lodash";
import _ from "lodash";

Vue.use(VueLodash, { name: "custom", lodash: _ });

export default {
  props:['selectedTeachers'],
  data() {
    return {
      currentWeek: 0,
      lastWeek: 0,
      average: 0,
      timeSpentPlanningActivities: {},
      numberOfActivityPlanned: {},
      timeSpentActivityReporting: {},
      numberOfLearningStoriesCreated: {}
    };
  },
  components: {
    VueApexCharts
  },
  methods: {
    ...mapActions("activity", ["fetchWeeklyActivityBySlug", "fetchWeeklyActivityBySlugWithTeachers"]),
    async getTrackerData(roomId, selectedTeachers = []) {
      let queryParams = '';
      if(selectedTeachers.length > 0){
        queryParams = `?teacherIds=${JSON.stringify(this.selectedTeachers)}`;
      }
      await this.fetchWeeklyActivityBySlugWithTeachers({roomId, queryParams})
        .then(async res => {
          let self = this;
          let response = await res.data.data;
          let a = [
            response.averagePlanActivity
              ? response.averagePlanActivity.average
              : 0
          ];
          let b = [
            response.averageLearningStories
              ? response.averageLearningStories.average
              : 0
          ];
          let c = [
            response.averagePlanActivityTimeTaken.length
              ? response.averagePlanActivityTimeTaken[0].averageTimeTaken
              : 0
          ];
          let d = [
            response.averageLearningStoriesTimeTaken.length
              ? response.averageLearningStoriesTimeTaken[0].averageTimeTaken
              : 0
          ];
          a.push(
            response.lastWeekPlanActivity
              ? response.lastWeekPlanActivity.numberOfActivities
              : 0
          );
          a.push(
            response.currentWeekPlanActivity
              ? response.currentWeekPlanActivity.numberOfActivities
              : 0
          );
          b.push(
            response.lastWeekLearningStories
              ? response.lastWeekLearningStories.numberOfActivities
              : 0
          );
          b.push(
            response.currentWeekLearningStories
              ? response.currentWeekLearningStories.numberOfActivities
              : 0
          );
          c.push(
            response.lastWeekTimeSpentPlanning.length
              ? response.lastWeekTimeSpentPlanning[0].timeTakenHour.toFixed(2)
              : 0
          );
          c.push(
            response.currentWeekTimeSpentPlanning.length
              ? response.currentWeekTimeSpentPlanning[0].timeTakenHour.toFixed(
                  2
                )
              : 0
          );
          d.push(
            response.lastWeekTimeSpentLearningStories.length
              ? response.lastWeekTimeSpentLearningStories[0].timeTakenHour.toFixed(
                  2
                )
              : 0
          );
          d.push(
            response.currentWeekTimeSpentLearningStories.length
              ? response.currentWeekTimeSpentLearningStories[0].timeTakenHour.toFixed(
                  2
                )
              : 0
          );
          let highestA = _.max(a, function(o) {
            return o;
          });
          highestA = parseInt(highestA);

          let highestB = _.max(b, function(o) {
            return o;
          });
          highestB = parseInt(highestB);

          let highestC = _.max(c, function(o) {
            return o;
          });
          highestC = parseFloat(highestC);

          let highestD = _.max(d, function(o) {
            return o;
          });
          highestD = parseFloat(highestD);

          self.numberOfActivityPlanned = {
            title: {
              text: "Number of Activities Planned",
              align: "center",
              offsetY: 0,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                color: "#263238"
              }
            },
            series: [
              {
                name: "Activity Planned",
                data: [
                  {
                    x: "Average",
                    y: Math.ceil(
                      response.averagePlanActivity
                        ? response.averagePlanActivity.average
                        : 0
                    )
                  },
                  {
                    x: "Last Week",
                    y: response.lastWeekPlanActivity
                      ? response.lastWeekPlanActivity.numberOfActivities
                      : 0
                  },
                  {
                    x: "This Week",
                    y: response.currentWeekPlanActivity
                      ? response.currentWeekPlanActivity.numberOfActivities
                      : 0
                  }
                ]
              }
            ],
            chart: {
              type: "bar",
              height: highestA == 0 ?200:350,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                distributed: true,
                columnWidth: "100%",
                dataLabels: {
                  position: "top"
                }
              }
            },
            colors: ["#73C1FF", "#8684FF", "#70CAC2"],
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#333"]
              },
              offsetY: -30
            },
            grid: {
              show: false
            },
            yaxis: {
              max: highestA + 1,
              title: {
                show: false
              },
              labels: {
                show: false
              }
            },
            legend: {
              show: false
            }
          };
          self.numberOfLearningStoriesCreated = {
            title: {
              text: "Number of Learning Stories Created",
              align: "center",
              offsetY: 0,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                color: "#263238"
              }
            },
            series: [
              {
                name: "Learning Stories Created",
                data: [
                  {
                    x: "Average",
                    y: response.averageLearningStories
                      ? response.averageLearningStories.average
                      : 0
                  },
                  {
                    x: "Last Week",
                    y: response.lastWeekLearningStories
                      ? response.lastWeekLearningStories.numberOfActivities
                      : 0
                  },
                  {
                    x: "This Week",
                    y: response.currentWeekLearningStories
                      ? response.currentWeekLearningStories.numberOfActivities
                      : 0
                  }
                ]
              }
            ],
            chart: {
              type: "bar",
              height: highestB == 0 ?200:350,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                distributed: true,
                columnWidth: "100%",
                dataLabels: {
                  position: "top"
                }
              }
            },
            colors: ["#73C1FF", "#8684FF", "#70CAC2"],
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#333"]
              },
              offsetY: -30
            },
            grid: {
              show: false
            },
            yaxis: {
              max: highestB + 1,
              title: {
                show: false
              },
              labels: {
                show: false
              }
            },
            legend: {
              show: false
            }
          };
          self.timeSpentPlanningActivities = {
            title: {
              text: "Time Spent Planning Activities (Minutes)",
              align: "center",
              offsetY: 0,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                color: "#263238"
              }
            },
            series: [
              {
                name: "Time Spent Planning Activities (Minutes)",
                data: [
                  {
                    x: "Average",
                    y: response.averagePlanActivityTimeTaken.length
                      ? response.averagePlanActivityTimeTaken[0].averageTimeTaken.toFixed(
                          2
                        )
                      : 0
                  },
                  {
                    x: "Last Week",
                    y: response.lastWeekTimeSpentPlanning.length
                      ? response.lastWeekTimeSpentPlanning[0].timeTakenHour.toFixed(
                          2
                        )
                      : 0
                  },
                  {
                    x: "This Week",
                    y: response.currentWeekTimeSpentPlanning.length
                      ? response.currentWeekTimeSpentPlanning[0].timeTakenHour.toFixed(
                          2
                        )
                      : 0
                  }
                ]
              }
            ],
            chart: {
              type: "bar",
              height: highestC == 0 ?200:350,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                distributed: true,
                columnWidth: "100%",
                dataLabels: {
                  position: "top"
                }
              }
            },
            colors: ["#73C1FF", "#8684FF", "#70CAC2"],
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#333"]
              },
              offsetY: -30
            },
            grid: {
              show: false
            },
            yaxis: {
              max: highestC + 0.1,
              title: {
                show: false
              },
              labels: {
                show: false
              }
            },
            legend: {
              show: false
            }
          };
          self.timeSpentActivityReporting = {
            title: {
              text: "Time Spent Completing Learning Stories (Minutes)",
              align: "center",
              offsetY: 0,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                color: "#263238"
              }
            },
            series: [
              {
                name: "Time Spent Completing Learning Stories (Minutes)",
                data: [
                  {
                    x: "Average",
                    y: response.averageLearningStoriesTimeTaken.length
                      ? response.averageLearningStoriesTimeTaken[0].averageTimeTaken.toFixed(
                          2
                        )
                      : 0
                  },
                  {
                    x: "Last Week",
                    y: response.lastWeekTimeSpentLearningStories.length
                      ? response.lastWeekTimeSpentLearningStories[0].timeTakenHour.toFixed(
                          2
                        )
                      : 0
                  },
                  {
                    x: "This Week",
                    y: response.currentWeekTimeSpentLearningStories.length
                      ? response.currentWeekTimeSpentLearningStories[0].timeTakenHour.toFixed(
                          2
                        )
                      : 0
                  }
                ]
              }
            ],
            chart: {
              type: "bar",
              height: highestD == 0 ?200:350,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                distributed: true,
                columnWidth: "100%",
                dataLabels: {
                  position: "top"
                }
              }
            },
            colors: ["#73C1FF", "#8684FF", "#70CAC2"],
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#333"]
              },
              offsetY: -30
            },
            grid: {
              show: false
            },
            yaxis: {
              max: highestD + 0.1,
              title: {
                show: false
              },
              labels: {
                show: false
              }
            },
            legend: {
              show: false
            }
          };
        })
        .catch(err => console.log(err));
    }
  },
  watch: {
    childId(res) {
      //this.getTrackerData(this.teacher.roomId + "?childId=" + res);
    },
    async selectedTeachers(res) {
      await this.getTrackerData(this.teacher.roomId, this.selectedTeachers)
    }
  },
  mounted() {
    // if(this.$store.state.child.selectedChildId != ""){
    //    this.getTrackerData(this.teacher.roomId + "?childId=" + this.$store.state.child.selectedChildId);
    // }

    this.getTrackerData(this.teacher.roomId, this.selectedTeachers);
  },
  computed: {
    teacher() {
      return this.$store.state.AppActiveUser;
    },
    childId() {
      return this.$store.state.child.selectedChildId;
    }
  }
};
</script>
