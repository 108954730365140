<template>
  <div>
    <!--EYLF Checklist-->
    <vx-card
      class="mb-base eylf-card"
      v-for="(category, key) in categories"
      :key="key"
    >
      <vs-row>
        <vs-col
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
          class="eylf-col"
          :key="key + 2"
        >
          <h2>Learning Framework {{ category.title }}</h2>
          <vs-row class="title-row">
            <vs-col vs-lg="10" vs-sm="10" vs-xs="10">
              <h3 class="h4 font-semibold">Outcomes</h3>
            </vs-col>
            <vs-col vs-lg="2" vs-sm="2" vs-xs="2" class="pr-0">
              <h3 class="h4 font-semibold">Learning Stories</h3>
            </vs-col>
          </vs-row>
          <vs-row
            v-for="(outcome, index) in category.eylfChecklists"
            class="eylf-content-row"
            :key="index + 1"
          >
            <vs-col vs-lg="10" vs-sm="10" vs-xs="10">
              <h3 class="h4 mb-3" style="font-weight: 600; line-height: 24px;">
                {{ outcome.title }}
              </h3>
              <p>{{ outcome.description }}</p>
            </vs-col>
            <vs-col vs-lg="2" vs-sm="2" vs-xs="2">
              <p class="h4 font-semibold">{{ getCount(outcome._id) }}</p>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      categories: [],
      eylfChecklist: [],
      eylfChecklistPractices: [],
      eylfChecklistPrinciples: [],
      eylfChecklistOutcomes: [],
      teacher: {}
    };
  },
  props: ["childId", "childrens", "selectedTeachers"],
  methods: {
    ...mapActions("teacher", ["getEylfsWithLearningStory"]),
    ...mapActions("eylfCategory", [
      "fetchEylfCategoryForTeacherByLearningCenterId",
      "fetchEYLFCategoryWithFrameworkByCenterId"
    ]),
    async getCategories(centerId) {
      await this.fetchEYLFCategoryWithFrameworkByCenterId(centerId)
        .then(res => {
          this.categories = res.data.data ? res.data.data.eylfCategories : null;
        })
        .catch(err => console.log(err));
    },
    async getEylfChecklist(centerId, childId = null, selectedTeachers = []) {
      let param = "";
      if (childId) {
        param = "?childId=" + childId;
      }

      if (selectedTeachers.length > 0) {
        if (!param) {
          param = `?teacherIds=${JSON.stringify(this.selectedTeachers)}`;
        } else {
          param = param.concat(
            `&teacherIds=${JSON.stringify(this.selectedTeachers)}`
          );
        }
      }
      await this.getEylfsWithLearningStory(centerId + param).then(response => {
        this.eylfChecklist = response.data.data;
      });
    },
    getCount(id) {
      let list = _.find(this.eylfChecklist, item => {
        return item.eylfChecklists._id === id;
      });
      return list ? list.count : 0;
    }
  },
  watch: {
    childId(res) {
      this.getEylfChecklist(
        this.teacher.learningCenterId,
        res,
        this.selectedTeachers
      );
    },
    selectedTeachers(res) {
      this.getEylfChecklist(this.teacher.learningCenterId, this.childId, res);
    }
  },
  created() {
    this.teacher = this.$store.state.AppActiveUser;
    this.getEylfChecklist(
      this.teacher.learningCenterId,
      this.childId,
      this.selectedTeachers
    );
    this.getCategories(this.teacher.learningCenterId);
  },
  computed: {
    // childId() {
    //   return this.$store.state.child.selectedChildId;
    // }
  }
};
</script>

<style lang="scss">
table {
  border-collapse: collapse;
}

table tr td {
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  padding: 10px;
}

table tr td:first-child {
  border-left: none;
}

.vs-tooltip {
  z-index: 100000;
}

.demo-alignment {
  img {
    margin-top: 0 !important;
  }
}

.professional-development-table {
  th {
    &:first-child {
      min-width: 120px;
      width: 120px;
      @media (min-width: 1200px) {
        min-width: 160px;
        width: 160px;
      }
      @media (min-width: 1480px) {
        min-width: 200px;
        width: 200px;
      }
    }

    &:nth-child(2) {
      min-width: 120px;
      width: 120px;
      @media (min-width: 1200px) {
        min-width: 250px;
        width: 250px;
      }
      @media (min-width: 1480px) {
        min-width: 300px;
        width: 300px;
      }
    }

    &:last-child {
      min-width: 500px;
      width: 500px;
      @media (min-width: 1200px) {
        min-width: 700px;
        width: 700px;
      }
      @media (min-width: 1480px) {
        min-width: 800px;
        width: 800px;
      }
    }
  }

  .vs-con-textarea {
    margin-bottom: 0;
  }

  .vs-textarea {
    min-height: 60px;
    height: 60px;
  }

  .vs-button {
    padding: 10px !important;
  }
}

.vs-popup {
  @media (min-width: 1480px) {
    width: 800px !important;
  }

  .vs-popup--content {
    padding: 30px;
  }

  .vs-popup--header {
    height: 0;

    .vs-popup--title {
      h3 {
        display: none;
      }
    }
  }

  .vs-popup--close {
    transform: none !important;
    background: none !important;
    box-shadow: none;
    position: relative;
    top: 35px;
    right: 20px;
    font-size: 25px;
    color: #2b2d3d;

    &:hover {
      color: #2d9ad5;
    }
  }
}

.range {
  position: relative;
  width: 100%;
  height: 5px;

  input[type="radio"] {
    border: 13.5px solid #2898d5;
    border-radius: 50%;
    width: 27px;
    position: absolute;
    height: 27px;
    -webkit-appearance: none;
    top: -12px;
    right: -12px;
    transition: 0.4s all ease-in-out;
    z-index: 1;
    opacity: 0;

    &:checked {
      opacity: 1;
    }
  }

  .range-labels {
    background: #f0f0f0;
    height: 6px;
    border-radius: 3px;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      position: relative;
      width: calc(100% / 5);
      text-align: left;
      color: black;
      font-size: 14px;
      height: 6px;
      top: -9px;
      cursor: pointer;

      &:first-child {
        width: 0;
      }

      &::before {
        content: "";
        position: absolute;
        top: -4px;
        right: 0;
        width: 3px;
        height: 13px;
        background: #606060;
      }

      label {
        position: absolute;
        top: 20px;
        right: -2px;
      }
    }
  }
}

.tag {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  display: inline-block;
  background: #2898d5;
  position: relative;
  top: 5px;
  margin: 0 5px;
}

.vs-tooltip {
  color: #222222;
  font-size: 15px;
  line-height: 20px;
  padding: 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
  min-width: 300px !important;
}

.title-row {
  border-bottom: 1px solid rgba(96, 96, 96, 0.3);
  padding-bottom: 15px;

  .vs-col {
    padding-right: 150px;
  }
}

.eylf-content-row {
  padding: 25px 0 15px;

  .vs-col {
    padding-right: 150px;
  }
}
</style>
