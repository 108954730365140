<template>
  <vx-card>
    <vs-row class="bg-grid-color mb-4">
      <vs-col
        vs-type="flex"
        vs-lg-justify="start"
        vs-sm-justify="center"
        vs-align="center"
        vs-lg="6"
        vs-sm="12"
      >
        <h2>Fast Checks</h2>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="start"
        vs-sm-justify="center"
        vs-align="center"
        vs-lg="6"
        vs-sm="12"
      >
        <ul class="ml-auto status-list">
          <li>
            <span class="rounded-full" style="background: #74cac2;"></span>
            <span class="font-regular">Individual Average</span>
          </li>
          <li>
            <span class="rounded-full" style="background: #27378E;"></span>
            <span class="font-regular">Room Trend</span>
          </li>
        </ul>
      </vs-col>
    </vs-row>
    <div class="con-tablex vs-table--content my-insights-circles">
      <table width="100%">
        <thead>
          <tr>
            <th class="pl-0 pr-10" style="width: 30%">Fast Check Comparison</th>
            <th class="pl-0">Professional Development</th>
            <th class="pl-0">Achieving</th>
            <th class="pl-0">Developing</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(fastCheck, key) in fastChecks" :key="key">
            <td class="px-5">
              <div class="flex w-full justify-between">
                <span>{{ fastCheck.presetDetail.name }}</span>
                <!--<span
                  >Average: <strong>{{ fastCheck.average }}</strong></span
                >-->
              </div>
              <ul class="rank-bar">
                <!-- <li>
                  <span
                    v-if="fastCheck.average === 0"
                    class="tag average-tag"
                  ></span>
                 <span style="position:absolute;top:8px;">0</span> 
                  
                  <span
                    v-if="fastCheck.trend === 0"
                    class="tag trend-tag"
                    :style="
                      fastCheck.average === 0 && fastCheck.trend === 0
                        ? 'border:5px solid #27378E !important;  top:-14px !important; background:#74cac2 !important'
                        : ''
                    "
                  ></span>
                </li> -->
                <li>
                  <span
                    v-if="fastCheck.average && Number(fastCheck.average.toFixed()) === 1"
                    class="tag average-tag"
                  ></span>
                  <span  style="position:absolute;top:8px;">1</span> 
                  
                  <span
                    v-if="Number(fastCheck.trend.toFixed()) === 1"
                    class="tag trend-tag"
                    :style="
                      fastCheck.average && Number(fastCheck.average.toFixed()) === 1 && Number(fastCheck.trend.toFixed()) === 1
                        ? 'border:5px solid #27378E !important;  top:-14px !important; background:#74cac2'
                        : ''
                    "
                  ></span>
                </li>
                <li>
                  <span
                    v-if="fastCheck.average && Number(fastCheck.average.toFixed()) === 2"
                    class="tag average-tag"
                  ></span>
                  <span  style="position:absolute;top:8px;">2</span> 
                  
                  <span
                    v-if="Number(fastCheck.trend.toFixed()) === 2"
                    class="tag trend-tag"
                    :style="
                      fastCheck.average && Number(fastCheck.average.toFixed()) === 2 && Number(fastCheck.trend.toFixed()) === 2
                        ? 'border:5px solid #27378E !important;  top:-14px !important; background:#74cac2'
                        : ''
                    "
                  ></span>
                </li>
                <li>
                  <span
                    v-if="fastCheck.average && Number(fastCheck.average.toFixed()) === 3"
                    class="tag average-tag"
                  ></span>
                  <span  style="position:absolute;top:8px;">3</span> 
                  
                  <span
                    v-if="Number(fastCheck.trend.toFixed()) === 3"
                    class="tag trend-tag"
                    :style="
                      fastCheck.average && Number(fastCheck.average.toFixed()) === 3 && Number(fastCheck.trend.toFixed()) === 3
                        ? 'border:5px solid #27378E !important;  top:-14px !important; background:#74cac2'
                        : ''
                    "
                  ></span>
                </li>
                <li>
                  <span
                    v-if="fastCheck.average && Number(fastCheck.average.toFixed()) === 4"
                    class="tag average-tag"
                  ></span>
                  <span  style="position:absolute;top:8px;">4</span> 
                  
                  <span
                    v-if="Number(fastCheck.trend.toFixed()) === 4"
                    class="tag trend-tag"
                    :style="
                      fastCheck.average && Number(fastCheck.average.toFixed()) === 4 && Number(fastCheck.trend.toFixed()) === 4
                        ? 'border:5px solid #27378E !important;  top:-14px !important; background:#74cac2'
                        : ''
                    "
                  ></span>
                </li>
                <li style="width: 2%">

                  <span
                    v-if="fastCheck.average && Number(fastCheck.average.toFixed()) === 5"
                    class="tag average-tag"
                  ></span>
                  <span  style="position:absolute;top:8px;">5</span> 
                  
                  <span
                    v-if="Number(fastCheck.trend.toFixed()) === 5"
                    class="tag trend-tag"
                    :style="
                      fastCheck.average && Number(fastCheck.average.toFixed()) === 5 && Number(fastCheck.trend.toFixed()) === 5
                        ? 'border:5px solid #27378E !important;  top:-14px !important; background:#74cac2'
                        : ''
                    "
                  ></span>
                </li>
              </ul>
            </td>
            <td class="pl-0">
              <a
                href="javascript:;"
                class="font-semibold"
                @click="
                  detailPage(fastCheck.presetDetail._id, fastCheck.average)
                "
              >
                {{ fastCheck.presetDetail.name }} - Professional Development</a
              >
            </td>
            <td class="pl-0">
              <ul>
                <li
                  style="display: inline-block"
                  v-for="(child, key) in getTopThreeChildren(fastCheck.childs)"
                  :key="key"
                >
                  <vs-avatar
                    @click="selectChild(child.childId)"
                    size="50px"
                    :src="child.childDetail.photo"
                    :title="child.childDetail.name"
                  />
                </li>
              </ul>
            </td>
            <td class="pl-0">
              <ul>
                <li
                  style="display: inline-block"
                  v-for="(child, key) in getBottomThreeChildren(
                    fastCheck.childs
                  )"
                  :key="key"
                >
                  <vs-avatar
                    @click="selectChild(child.childId)"
                    size="50px"
                    :src="child.childDetail.photo"
                    :alt="child.childDetail.name"
                  />
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </vx-card>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";
import VueLodash from "vue-lodash";
import lodash from "lodash";

Vue.use(VueLodash, { name: "custom", lodash: lodash });

export default {
  data() {
    return {
      fastChecks: [],

    };
  },
  props: ["child","childrens", "selectedTeachers"],
  methods: {
    ...mapActions("fastCheck", ["fetchFastCheckCompare"]),
    getTopThreeChildren(children) {
      let data = lodash.orderBy(children, ["childAverage"], ["desc"]);
      return lodash.take(data, 3);
    },
    detailPage(fastCheckPresetId, rating) {
      let routeData = this.$router.resolve({
        name: "fast-check-document",
        params: { fastCheckPresetId: fastCheckPresetId, rating: rating }
      });
      window.open(routeData.href, "_blank");
    },
    getBottomThreeChildren(children) {
      let a = lodash.orderBy(children, ["childAverage"], ["asc"]);
      return lodash.take(a, 3);
    },
    async getFastCheck(roomId, teacherId) {
      this.$vs.loading();
      const data = {
        roomId: roomId,
        teacherId: teacherId,
        childId:this.child
      };
      let param;
       if (this.selectedTeachers && this.selectedTeachers.length > 0) {
         data.selectedTeachers = this.selectedTeachers;
      }
      await this.fetchFastCheckCompare(data).then(res => {
        this.$vs.loading.close();
        this.fastChecks = res.data.data;
      });
      this.$vs.loading.close();
    },
    selectChild(id) {
      this.$router.push({ name: "child-profile", params: { childId: id } });
    }
  },
  async mounted() {

    if(this.child == ""){
      await this.$emit('setChildFromFastcheck', this.childrens[0]._id)
    }
    await this.getFastCheck(this.teacher.roomId, this.teacher._id);
  },
  computed: {
    teacher() {
      return this.$store.state.AppActiveUser;
    }
  },
  watch: {
    child() {
      this.getFastCheck(this.teacher.roomId, this.teacher._id);
    },
    selectedTeachers(){
      this.getFastCheck(this.teacher.roomId, this.teacher._id);
    }
  },
};
</script>
<style lang="scss">

.tag {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  display: inline-block;

  &.average-tag {
    background: #74cac2;
  }

  &.trend-tag {
    background: #27378e;
  }
  
}




.rank-bar {
  background: #ededed;
  height: 6px;
  border-radius: 3px;
  display: flex;
  width: 100%;
  margin: 30px 0 0;
  @media (min-width: 901px) {
    margin: 50px 0;
  }

  li {
    width: calc(100% / 4);
    position: relative;

    .tag {
      position: absolute;
      top: -8px;
      right: 0;
    }
  }
}
</style>
