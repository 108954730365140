<template>
  <vx-card>
    <h2>Core Skills Used in Documented Experiences</h2>
    <div class="con-tablex vs-table--content border-less-table">
    <table class="w-full mb-5">
      <thead>
      <tr class="text-center">
        <th></th>
        <th v-for="(coreSkill,key) in currentWeekCoreSkills" :key="key">
              <span class="img-block mb-0">
                    <img :src="coreSkill.icon ?coreSkill.icon :  $defaultImage" :alt="coreSkill.name"
                         class="product-img h-8 object-contain"/>
                  </span>
          {{ coreSkill.name }}
        </th>
      </tr>
      </thead>
      <tr>
        <td>
          <strong>This week</strong>
        </td>
        <td align="center" v-for="(coreSkill, key) in currentWeekCoreSkills"
            :set="s=coreSkill.activities ? coreSkill.activities.numberOfActivities : 0" :key="key" class="relative">
          {{ s }}
          <i v-if="(s > (lastWeekCoreSkills[key].activities ? lastWeekCoreSkills[key].activities.numberOfActivities:0))"
             class="fa fa-caret-up fa-2x absolute ml-2 success"></i>
          <i v-if="(s < (lastWeekCoreSkills[key].activities ? lastWeekCoreSkills[key].activities.numberOfActivities:0))"
             class="fa fa-caret-down fa-2x absolute ml-2 danger"></i>

        </td>
      </tr>
      <tr>
        <td>
          <strong>Last Week</strong>
        </td>
        <td align="center" v-for="(coreSkill, key) in lastWeekCoreSkills"
            :set="a=coreSkill.activities ? coreSkill.activities.numberOfActivities : 0" :key="key" class="relative">
          {{ a }}
          <i
            v-if="(a > (currentWeekCoreSkills[key].activities ? currentWeekCoreSkills[key].activities.numberOfActivities:0))"
            class="fa fa-caret-up fa-2x absolute ml-2 success"></i>
          <i
            v-if="(a < (currentWeekCoreSkills[key].activities ? currentWeekCoreSkills[key].activities.numberOfActivities:0))"
            class="fa fa-caret-down fa-2x absolute ml-2 danger"></i>
        </td>
      </tr>
      <tr>
        <td>
          <strong>Average</strong>
        </td>
        <td align="center" v-for="(coreSkill,key) in averageCoreSKills" :key="key">
          {{
            getAverage(coreSkill.average)
          }}
        </td>
      </tr>
    </table>
    </div>

    <h2>Ways of Learning Used in Documented Experiences</h2>
    <div class="con-tablex vs-table--content border-less-table">
    <table class="w-full mb-5">
      <thead>
      <tr class="text-center">
        <th></th>
        <th v-for="(waysOfLearning,key) in currentWeekWaysOfLearnings" :key="key">
              <span class="img-block mb-0">
                    <img :src="waysOfLearning.icon ?waysOfLearning.icon :  $defaultImage" :alt="waysOfLearning.name"
                         class="product-img h-8 object-contain"/>
                  </span>
          {{ waysOfLearning.name }}
        </th>
      </tr>
      </thead>
      <tr>
        <td>
          <strong>This week</strong>
        </td>
        <td align="center" v-for="(waysOfLearning, key) in currentWeekWaysOfLearnings"
            :set="s=waysOfLearning.activities ? waysOfLearning.activities.numberOfActivities : 0" :key="key" class="relative">
          {{ s }}
          <i
            v-if="(s > (lastWeekWaysOfLearnings[key].activities ? lastWeekWaysOfLearnings[key].activities.numberOfActivities:0))"
            class="fa fa-caret-up fa-2x absolute ml-2 success"></i>
          <i
            v-if="(s < (lastWeekWaysOfLearnings[key].activities ? lastWeekWaysOfLearnings[key].activities.numberOfActivities:0))"
            class="fa fa-caret-down fa-2x absolute ml-2 danger"></i>
        </td>
      </tr>
      <tr>
        <td>
          <strong>Last Week</strong>
        </td>
        <td align="center" v-for="(waysOfLearning, key) in lastWeekWaysOfLearnings"
            :set="s=waysOfLearning.activities ? waysOfLearning.activities.numberOfActivities : 0" :key="key" class="relative">
          {{ s }}
          <i
            v-if="(s > (currentWeekWaysOfLearnings[key].activities ? currentWeekWaysOfLearnings[key].activities.numberOfActivities:0))"
            class="fa fa-caret-up fa-2x absolute ml-2 success"></i>
          <i
            v-if="(s < (currentWeekWaysOfLearnings[key].activities ? currentWeekWaysOfLearnings[key].activities.numberOfActivities:0))"
            class="fa fa-caret-down fa-2x absolute ml-2 danger"></i>
        </td>
      </tr>
      <tr>
        <td>
          <strong>Average</strong>
        </td>
        <td align="center" v-for="(waysOfLearning,key) in averageWaysOfLearnings" :key="key">
          {{
            getAverage(waysOfLearning.average)
          }}
        </td>
      </tr>
    </table>
  </div>

    <h2>STREAM Used in Documented Experiences</h2>
    <div class="con-tablex vs-table--content border-less-table">
    <table class="w-full mb-5">
      <thead>
      <tr class="text-center">
        <th></th>
        <th v-for="(stream,key) in currentWeekStream" :key="key">
              <span class="img-block mb-0">
                    <img :src="stream.icon ?stream.icon :  $defaultImage" :alt="stream.name"
                         class="product-img h-8 object-contain"/>
                  </span>
          {{ stream.name }}
        </th>
      </tr>
      </thead>
      <tr>
        <td>
          <strong>This week</strong>
        </td>
        <td align="center" v-for="(stream, key) in currentWeekStream"
            :set="s=stream.activities ? stream.activities.numberOfActivities : 0" :key="key" class="relative">
          {{ s }}
          <i v-if="(s > (lastWeekStream[key].activities ? lastWeekStream[key].activities.numberOfActivities:0))"
             class="fa fa-caret-up fa-2x absolute ml-2 success"></i>
          <i v-if="(s < (lastWeekStream[key].activities ? lastWeekStream[key].activities.numberOfActivities:0))"
             class="fa fa-caret-down fa-2x absolute ml-2 danger"></i>
        </td>
      </tr>
      <tr>
        <td>
          <strong>Last Week</strong>
        </td>
        <td align="center" v-for="(stream, key) in lastWeekStream"
            :set="s=stream.activities ? stream.activities.numberOfActivities : 0" :key="key" class="relative">
          {{ s }}
          <i v-if="(s > (currentWeekStream[key].activities ? currentWeekStream[key].activities.numberOfActivities:0))"
             class="fa fa-caret-up fa-2x absolute ml-2 success"></i>
          <i v-if="(s < (currentWeekStream[key].activities ? currentWeekStream[key].activities.numberOfActivities:0))"
             class="fa fa-caret-down fa-2x absolute ml-2 danger"></i>
        </td>
      </tr>
      <tr>
        <td>
          <strong>Average</strong>
        </td>
        <td align="center" v-for="(stream,key) in averageStrea" :key="key">
          {{
            getAverage(stream.average)
          }}
        </td>
      </tr>
    </table>
  </div>

  </vx-card>
</template>

<script>
import axios from "@/http/axios";

export default {
  data() {
    return {
      currentWeekCoreSkills: [],
      lastWeekCoreSkills: [],
      averageCoreSKills: [],
      currentWeekWaysOfLearnings: [],
      lastWeekWaysOfLearnings: [],
      averageWaysOfLearnings: [],
      currentWeekStream: [],
      lastWeekStream: [],
      averageStrea: []

    }
  },
  props: ["childId", "selectedTeachers"],
  methods: {
    async getCoreSkills(childId, selectedTeachers = []) {
      let param = childId ? "?childId=" + childId : "";

      if (selectedTeachers.length > 0) {
        if (!param) {
          param = `?teacherIds=${JSON.stringify(this.selectedTeachers)}`;
        } else {
          param = param.concat(
            `&teacherIds=${JSON.stringify(this.selectedTeachers)}`
          );
        }
      }

      await axios.get('/teacher/core-skill-compare/' + this.teacher.roomId + param).then(async (res) => {
        this.currentWeekCoreSkills = res.data.data.currentWeek;
        this.lastWeekCoreSkills = res.data.data.lastWeek;
        this.averageCoreSKills = res.data.data.averageWeek;
      }).catch((err) => console.log(err))
    },
    async getWaysOfLearnings(childId, selectedTeachers = []) {
      let param = childId ? "?childId=" + childId : "";

       if (selectedTeachers.length > 0) {
        if (!param) {
          param = `?teacherIds=${JSON.stringify(this.selectedTeachers)}`;
        } else {
          param = param.concat(
            `&teacherIds=${JSON.stringify(this.selectedTeachers)}`
          );
        }
      }

      await axios.get('/teacher/ways-of-learning-compare/' + this.teacher.roomId + param).then(async (res) => {
        this.currentWeekWaysOfLearnings = res.data.data.currentWeek;
        this.lastWeekWaysOfLearnings = res.data.data.lastWeek;
        this.averageWaysOfLearnings = res.data.data.averageWeek;
      }).catch((err) => console.log(err))
    },
    async getStream(childId, selectedTeachers = []) {
      let param = childId ? "?childId=" + childId : "";

      if (selectedTeachers.length > 0) {
        if (!param) {
          param = `?teacherIds=${JSON.stringify(this.selectedTeachers)}`;
        } else {
          param = param.concat(
            `&teacherIds=${JSON.stringify(this.selectedTeachers)}`
          );
        }
      }

      await axios.get('/teacher/stream-compare/' + this.teacher.roomId + param).then(async (res) => {
        this.currentWeekStream = res.data.data.currentWeek;
        this.lastWeekStream = res.data.data.lastWeek;
        this.averageStrea = res.data.data.averageWeek;
      }).catch((err) => console.log(err))
    },

    getAverage(val) {
      return Math.round(val);

    }
  },
  watch: {
    async childId(res) {
      this.$vs.loading();
      await this.getCoreSkills(res, this.selectedTeachers);
      await this.getWaysOfLearnings(res,  this.selectedTeachers);
      await this.getStream(res,  this.selectedTeachers);
      this.$vs.loading.close();
    }, 
    async selectedTeachers(res){
      this.$vs.loading();
      await this.getCoreSkills(this.childId, res);
      await this.getWaysOfLearnings(this.childId,  res);
      await this.getStream(this.childId,  res);
      this.$vs.loading.close();
    }
  },
  async created() {
    if(this.childId != ""){
      await this.getCoreSkills(this.childId, this.selectedTeachers);
      await this.getWaysOfLearnings(this.childId, this.selectedTeachers);
      await this.getStream(this.childId, this.selectedTeachers);
    }
    // console.log(this.childId,"child Id");
    // await this.getCoreSkills(this.childId);
    // await this.getWaysOfLearnings(this.childId);
    // await this.getStream(this.childId);

  },
  computed: {
    teacher() {
      return this.$store.state.AppActiveUser;
    },
    // childId() {
    //   return this.$store.state.child.selectedChildId;
    // }
  }
}
</script>
<style>
.success {
  color: green;
}

.danger {
  color: red;
}
</style>
