<template>
  <vx-card class="mb-base overflow-space" style="border-radius: 8px;">
    <h2>Children</h2>
    <horizontal-scroll>
    <ul class="children-list mb-4">
      <span v-for="(child, key) in $store.state.child.children" v-bind:key="child._id">
        <li :class=" key === activeChildKey ? 'relative inline-block active' : 'relative inline-block'" style="max-width:125px;margin-right:35px;text-align:center;">
          <div class="avatar-div">
          <vs-avatar size="large" :src="child.photo" @click="selectChild(child._id, key)"/>
          <span class="badge">  {{ child.activities.length ? child.activities[0].numberOfActivities : 0 }}</span></div>
          <p style="font-size: small;">{{firstNameOnly(child.fullName)}}</p>
        </li>
      </span>
    </ul>
    </horizontal-scroll>
  </vx-card>
</template>

<script>
import {mapActions} from "vuex";
import HorizontalScroll from 'vue-horizontal-scroll'
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'

export default {
 components: {HorizontalScroll },

  data() {
    return {
      activeChildKey: '',
      childrenList: '',
      teacher: '',
      selectedChildId: ''
    }
  },
  methods: {
    ...mapActions("child", [
      "fetchChildrenByRoomId",
      "fetchChildrenFilterByRoomId",
      "setSelectedChildId"
    ]),
    firstNameOnly(fullName){
      let name = fullName.split(' ');
      return name[0];
    },
    selectChild(val, key) {
      if (val === this.selectedChildId) {
        this.selectedChildId = '';
        val = '';
        key = '';

      }
      this.activeChildKey = key;
      this.selectedChildId = val;
      this.setSelectedChildId(val);

      this.$router.push({name: 'child-profile', params: {childId: this.selectedChildId}});
    }
  },

  async mounted() {
    let self = this;
    self.room = self.roomId
    setTimeout(function () {
      self.childrenList = self.$store.state.child.children;
    }, 500)


  },

  async created() {
    this.teacher = this.$store.state.AppActiveUser;
    await this.fetchChildrenByRoomId(this.teacher.roomId);

  }

}
</script>

<style lang="scss" scoped>
.children-list {
  li {
    &.active {
      .con-vs-avatar.large {
        border: 3px solid #2898D5;
      }
    }
  }
}

.con-vs-avatar.large {
  width: 50px;
  height: 50px;
  /* margin-right: 19px;
  @media(min-width: 768px) {
    margin-right: 35px;
  } */
}
.avatar-div{
  width: min-content;
  position: relative;
}

.badge {
  background: #2898D5;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 13px;
  font-weight: 600;
  line-height: 28px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: -3px;
  right: -10px;
  /* @media (min-width: 768px) {
    right: -10px;
  } */
}
</style>
