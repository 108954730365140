<template>
  <div>
    <!--Assessment Subjects Block-->
    <vs-row class="mb-5 mx-10 w-auto">
      <vs-col class="text-center w-full xl:w-1/3 rounded-lg mb-5 md:mb-0">
        <vx-card class="h-full mx-0 xl:m-0 xl:mr-5 xl:py-10">
          <h2>Core Skills</h2>
          <vue-apex-charts
            type="donut"
            height="300"
            :options="donutChart.chartOptions"
            :series="donutChart.series"
          ></vue-apex-charts>
        </vx-card>
      </vs-col>
      <vs-col class="text-center w-full xl:w-1/3 rounded-lg mb-5 md:mb-0">
        <vx-card class="h-full mx-0 xl:m-0 xl:mx-5 xl:py-10">
          <h2>Ways Of Learning</h2>
          <vue-apex-charts
            type="donut"
            height="300"
            :options="waysOfLearningChart.chartOptions"
            :series="waysOfLearningChart.series"
          ></vue-apex-charts>
        </vx-card>
      </vs-col>
      <vs-col class="text-center w-full xl:w-1/3 rounded-lg ">
        <vx-card class="h-full mx-0 xl:m-0 xl:ml-5 xl:py-10">
          <h2>STREAM</h2>
          <vue-apex-charts
            type="donut"
            height="300"
            :options="streamChart.chartOptions"
            :series="streamChart.series"
          ></vue-apex-charts>
        </vx-card>
      </vs-col>
    </vs-row>
    <tracker :selectedTeachers="selectedTeachers"></tracker>

    <assessment-subject-compare :childId="childId" :selectedTeachers="selectedTeachers"></assessment-subject-compare>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import axios from "../../../../http/axios/index";
import { mapActions } from "vuex";
import AssessmentSubjectCompare from "@/views/pages/teacher/myInsight/AssessmentSubjectCompare";
import Tracker from "./Tracker";

export default {
  components: { VueApexCharts, Tracker, AssessmentSubjectCompare },
  props: ["childId", "childrens", "selectedTeachers"],
  data() {
    return {
      themeColors: [
        "#7F84BF",
        "#D785B7",
        "#F2956B",
        "#F2D06C",
        "#73C9C1",
        "#F16B6B",
        "#80BEE7",
        "#557C96",
        "#EEC1DD"
      ],
      donutChart: {
        series: [],
        chartOptions: {}
      },
      waysOfLearningChart: {
        series: [],
        chartOptions: {}
      },
      streamChart: {
        series: [],
        chartOptions: {}
      },
      firstLoad: true
    };
  },
  watch: {
    async childId(res) {
      this.$vs.loading();
      await this.getCoreSkills(res, this.selectedTeachers);
      await this.getWaysOfLearnings(res, this.selectedTeachers);
      await this.getStream(res, this.selectedTeachers);
      this.$vs.loading.close();
    },
    async selectedTeachers(res) {
      this.$vs.loading();
      await this.getCoreSkills(this.childId, res);
      await this.getWaysOfLearnings(this.childId, res);
      await this.getStream(this.childId, res);
      this.$vs.loading.close();
    }
  },
  methods: {
    ...mapActions("child", ["setSelectedChildId"]),
    async getCoreSkills(childId = null, selectedTeachers = []) {
      let param = childId ? "?childId=" + childId : "";

      if (selectedTeachers.length > 0) {
        if (!param) {
          param = `?teacherIds=${JSON.stringify(this.selectedTeachers)}`;
        } else {
          param = param.concat(
            `&teacherIds=${JSON.stringify(this.selectedTeachers)}`
          );
        }
      }
      this.$vs.loading();
      await axios
        .get(
          "/teacher/core-skill-activity-count/" + this.teacher.roomId + param
        )
        .then(async res => {
          this.$vs.loading.close();

          let coreSkills = res.data.data;
          let a = coreSkills.map(item => {
            return item.activities ? item.activities.numberOfActivities : 0;
          });
          let b = await coreSkills.map(item => {
            return item.name;
          });
          this.donutChart.series = a;
          this.donutChart.chartOptions = {
            labels: b,
            colors: this.themeColors,
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: false
            }
            // responsive: [
            //   {
            //     breakpoint: 992,
            //     options: {
            //       chart: {
            //         // width: 300,
            //       },
            //       legend: {
            //         position: "bottom"
            //       }
            //     }
            //   }
            // ]
          };
          this.$set(this.donutChart);
        })
        .catch(err => {
          console.log(err);
          if (err.response.status === 403) {
            this.$vs.loading.close();

            alert(
              "You dont have permission to visit this page. Please upgrade your package."
            );
            this.$router.push("/teacher/dashboard");
          }
        });
    },

    async getWaysOfLearnings(childId = null, selectedTeachers = []) {
      let param = childId ? "?childId=" + childId : "";

      if (selectedTeachers.length > 0) {
        if (!param) {
          param = `?teacherIds=${JSON.stringify(this.selectedTeachers)}`;
        } else {
          param = param.concat(
            `&teacherIds=${JSON.stringify(this.selectedTeachers)}`
          );
        }
      }

      await axios
        .get(
          "/teacher/ways-of-learning-activity-count/" +
            this.teacher.roomId +
            param
        )
        .then(async res => {
          let waysOfLearnings = res.data.data;
          let a = waysOfLearnings.map(item => {
            return item.activities ? item.activities.numberOfActivities : 0;
          });
          let b = await waysOfLearnings.map(item => {
            return item.name;
          });
          this.waysOfLearningChart.series = a;
          this.waysOfLearningChart.chartOptions = {
            labels: b,
            colors: this.themeColors,
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: false
            }
            // responsive: [
            //   {
            //     breakpoint: 992,
            //     options: {
            //       chart: {
            //         // width: 300,
            //       },
            //       legend: {
            //         position: "bottom"
            //       }
            //     }
            //   }
            // ]
          };
          this.$set(this.waysOfLearningChart);
        })
        .catch(err => {
          console.log(err);
          if (err.response.status === 403) {
            this.$vs.loading.close();

            alert(
              "You dont have permission to visit this page. Please upgrade your package."
            );
            this.$router.push("/teacher/dashboard");
          }
        });
    },

    async getStream(childId = null, selectedTeachers = []) {
      let param = childId ? "?childId=" + childId : "";

      if (selectedTeachers.length > 0) {
        if (!param) {
          param = `?teacherIds=${JSON.stringify(this.selectedTeachers)}`;
        } else {
          param = param.concat(
            `&teacherIds=${JSON.stringify(this.selectedTeachers)}`
          );
        }
      }

      await axios
        .get("/teacher/stream-activity-count/" + this.teacher.roomId + param)
        .then(async res => {
          let stream = res.data.data;
          let a = stream.map(item => {
            return item.activities ? item.activities.numberOfActivities : 0;
          });
          let b = await stream.map(item => {
            return item.name;
          });
          this.streamChart.series = a;
          this.streamChart.chartOptions = {
            labels: b,
            colors: this.themeColors,
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: false
            }
            // responsive: [
            //   {
            //     breakpoint: 992,
            //     options: {
            //       chart: {
            //         // width: 300,
            //       },
            //       legend: {
            //         position: "bottom"
            //       }
            //     }
            //   }
            // ]
          };
          this.$set(this.streamChart);
        })
        .catch(err => {
          console.log(err);
          if (err.response.status === 403) {
            this.$vs.loading.close();

            alert(
              "You dont have permission to visit this page. Please upgrade your package."
            );
            this.$router.push("/teacher/dashboard");
          }
        });
    }
  },

  async created() {
    if (this.childId != "") {
      await this.getCoreSkills(this.childId, this.selectedTeachers);
      await this.getWaysOfLearnings(this.childId, this.selectedTeachers);
      await this.getStream(this.childId, this.selectedTeachers);
    }
  },
  computed: {
    teacher() {
      return this.$store.state.AppActiveUser;
    }
    // childId() {
    //   return this.$store.state.child.selectedChildId;
    // }
  }
};
</script>
<style>
.apexcharts-legend {
  display: none !important;
}
</style>
