<template>
  <div>
    <vx-card class="mb-5">
      <h2>Observed Change in Children across Core Skills (0-5 Rating)</h2>
      <div class="con-tablex vs-table--content border-less-table">
        <table class="w-full mb-5">
          <thead>
            <tr class="text-center">
              <th></th>
              <th v-for="(coreSkill, key) in currentWeekCoreSkills" :key="key">
                <span class="img-block mb-0">
                  <img
                    :src="coreSkill.icon ? coreSkill.icon : $defaultImage"
                    :alt="coreSkill.name"
                    class="product-img h-8 object-contain"
                  />
                </span>
                {{ coreSkill.name }}
              </th>
            </tr>
          </thead>
          <tr>
            <td>
              <strong>This week</strong>
            </td>
            <td
              align="center"
              v-for="(coreSkill, key) in currentWeekCoreSkills"
              :set="
                (s = coreSkill.activities && coreSkill.activities.totalGrowth
                  ? Number(coreSkill.activities.totalGrowth.toFixed())
                  : 0)
              "
              :key="key"
              class="relative"
            >
              {{ s }}
              <i
                v-if="
                  s >
                    (lastWeekCoreSkills[key].activities
                      ? lastWeekCoreSkills[key].activities.totalGrowth
                      : 0)
                "
                class="fa fa-caret-up absolute ml-2 fa-2x success"
              ></i>
              <i
                v-if="
                  s <
                    (lastWeekCoreSkills[key].activities
                      ? lastWeekCoreSkills[key].activities.totalGrowth
                      : 0)
                "
                class="fa fa-caret-down absolute ml-2 fa-2x danger"
              ></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Last Week</strong>
            </td>
            <td
              align="center"
              v-for="(coreSkill, key) in lastWeekCoreSkills"
              :set="
                (a = coreSkill.activities && coreSkill.activities.totalGrowth
                  ? Number(coreSkill.activities.totalGrowth.toFixed())
                  : 0)
              "
              :key="key"
              class="relative"
            >
              {{ a }}
              <i
                v-if="
                  a >
                    (currentWeekCoreSkills[key].activities
                      ? currentWeekCoreSkills[key].activities.totalGrowth
                      : 0)
                "
                class="fa fa-caret-up absolute ml-2 fa-2x success"
              ></i>
              <i
                v-if="
                  a <
                    (currentWeekCoreSkills[key].activities
                      ? currentWeekCoreSkills[key].activities.totalGrowth
                      : 0)
                "
                class="fa fa-caret-down absolute ml-2 fa-2x danger"
              ></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Average</strong>
            </td>
            <td
              align="center"
              v-for="(coreSkill, key) in averageCoreSKills"
              :key="key"
            >
              {{ coreSkill.weeklyAverage ? Number(coreSkill.weeklyAverage.toFixed()) : 0 }}
            </td>
          </tr>
        </table>
      </div>
    </vx-card>
    <vx-card>
      <h2>Professional Development</h2>
      <div class="con-tablex vs-table--content">
        <table v-if="currentWeekCoreSkills">
          <tr
            class="border-none"
            v-for="(coreSkill, key) in currentWeekCoreSkills"
            :key="key"
          >
            <td class="border-none pl-0">
              <div class="demo-alignment">
                <img
                  :src="coreSkill.icon ? coreSkill.icon : $defaultImage"
                  width="45"
                  height="45"
                  class="inline-block h-8 object-contain"
                />
                {{ coreSkill.name }}
              </div>
            </td>
            <td class="border-none pl-10">
              <a href="#" @click="detailPage(coreSkill._id)"
                >{{ coreSkill.name }} - Professional Developments</a
              >
            </td>
          </tr>
        </table>
      </div>
    </vx-card>
  </div>
</template>
<script>
import axios from "@/http/axios";

export default {
  data() {
    return {
      currentWeekCoreSkills: [],
      lastWeekCoreSkills: [],
      averageCoreSKills: []
    };
  },
  props: ["childId", "childrens", "selectedTeachers"],
  watch: {
    childId(val) {
      this.getCoreSkills(val, this.selectedTeachers);
    },
    selectedTeachers(val) {
      this.getCoreSkills(this.childId, val);
    }
  },
  methods: {
    async getCoreSkills(childId = null, selectedTeachers = []) {
      let param = childId ? "?childId=" + childId : "";

      if (selectedTeachers.length > 0) {
        if (!param) {
          param = `?teacherIds=${JSON.stringify(this.selectedTeachers)}`;
        } else {
          param = param.concat(
            `&teacherIds=${JSON.stringify(this.selectedTeachers)}`
          );
        }
      }

      this.$vs.loading();
      await axios
        .get(
          "/teacher/core-skill/activity-growth/" + this.teacher.roomId + param
        )
        .then(async res => {
          this.$vs.loading.close();

          this.currentWeekCoreSkills = res.data.data.currentWeek;
          this.lastWeekCoreSkills = res.data.data.lastWeek;
          this.averageCoreSKills = res.data.data.averageWeek;
        })
        .catch(err => {
          console.log(err);
          if (err.response.status === 403) {
            this.$vs.loading.close();

            alert(
              "You dont have permission to visit this page. Please upgrade your package."
            );
            this.$router.push("/teacher/dashboard");
          }
        });
      this.$vs.loading.close();
    },
    getAverage(val) {
      return Math.round(val);
    },
    detailPage(coreSkillId) {
      let routeData = this.$router.resolve({
        name: "professional-development-detail",
        params: { coreSkillId: coreSkillId, rating: 1 }
      });
      window.open(routeData.href, "_blank");
    }
  },
  mounted() {
    this.getCoreSkills(this.childId, this.selectedTeachers);
  },
  computed: {
    teacher() {
      return this.$store.state.AppActiveUser;
    }
    // childId() {
    //   return this.$store.state.child.selectedChildId;
    // }
  }
};
</script>
