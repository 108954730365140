<template>
  <div>
    <!--Room teacher -->
    <!-- <room-teacher
      v-if="teacher"
      :room-id="teacher.roomId"
      :center-id="teacher.centerId"
      :logged-teacher="teacher"
    ></room-teacher> -->

    <vx-card
      class="mb-base"
      v-if="this.$store.state.AppActiveUser.role === 'leadTeacher'"
    >
      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        class="mb-5"
      >
        <vs-col class="flex w-full sm:w-1/2 mb-5 md:mb-0">
          <h2 class="mb-0">Teachers</h2>
        </vs-col>
        <vs-col class="flex w-full md:w-1/2" v-if="teacherList.length">
          <vs-button
            v-if="selectedTeachers.length !== totalTeacherIds.length"
            class="ml-auto"
            @click="selectAllTeachers"
          >
            Select All
          </vs-button>
          <vs-button v-else class="ml-auto" @click="unSelectAllTeachers">
            Unselect All
          </vs-button>
        </vs-col>
      </vs-row>

      <!-- Avatar -->
      <horizontal-scroll>
        <ul class="teacher-list-lead mb-4 outer" v-if="teacherList.length">
          <li
            v-for="(teacher, index) in teacherList"
            v-bind:key="index"
            :class="
              selectedTeachers.includes(teacher._id)
                ? 'relative inline-block active'
                : 'relative inline-block'
            "
            style="max-width:130px;margin-right:40px;text-align:center;"
          >
            <vs-avatar
              size="large"
              :src="teacher.profileImage"
              @click="selectTeacher(teacher, index)"
            />
            <h3
              class="h5"
              style="color: #289AD5; font-size: 14px; line-height: 18px;"
            >
              {{ firstNameOnly(teacher.fullName) }}
            </h3>
          </li>
        </ul>
        <span v-else>
          No Teachers in this room.
        </span>
      </horizontal-scroll>
    </vx-card>

    <!--Room Children-->
    <!-- <room-children></room-children> -->
    <vx-card class="mb-base" style="border-radius: 8px; padding-bottom: 40px;">
      <h2>Children</h2>
      <horizontal-scroll>
        <ul class="children-list mb-4">
          <span
            v-for="(child, key) in $store.state.child.children"
            v-bind:key="child._id"
          >
            <li
              :class="
                key === activeChildKey
                  ? 'relative inline-block active'
                  : 'relative inline-block'
              "
            >
              <vs-avatar
                size="large"
                :src="child.photo"
                @click="selectChild(child._id, key)"
              />
              <span class="badge">
                {{
                  child.activities.length
                    ? child.activities[0].numberOfActivities
                    : 0
                }}</span
              >
              <p style="font-size: small;">
                {{ firstNameOnly(child.fullName) }}
              </p>
            </li>
          </span>
        </ul>
      </horizontal-scroll>
    </vx-card>
    <div class="mt-5 tab-wrapper">
      <vs-tabs class="activity-tab " v-model="selectedTab">
        <vs-tab label="Learning Stories">
          <div>
            <learning-stories
              :childId="selectedChildId"
              :selectedTeachers="selectedTeachers"
            ></learning-stories>
          </div>
        </vs-tab>
        <vs-tab label="Core Skills">
          <div>
            <core-skill-tab
              :childId="selectedChildId"
              :selectedTeachers="selectedTeachers"
            ></core-skill-tab>
          </div>
        </vs-tab>
        <vs-tab label="Fast Checks">
          <div>
            <fast-check-tab
              :child="selectedChildId"
              :childrens="$store.state.child.children"
              :selectedTeachers="selectedTeachers"
              @setChildFromFastcheck="setChildFromFastcheck"
            ></fast-check-tab>
          </div>
        </vs-tab>
        <vs-tab label="Learning Framework">
          <div>
            <elyf-block
              :childId="selectedChildId"
              :selectedTeachers="selectedTeachers"
            ></elyf-block>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

// import RoomTeacher from "../RoomTeacher";
import RoomChildren from "../RoomChildren";
import LearningStories from "./LearningStories";
import ElyfBlock from "./Eylf";
import CoreSkillTab from "./CoreSkillTab";
import FastCheckTab from "./FastCheckTab";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
import _ from "lodash";

export default {
  components: {
    RoomChildren,
    LearningStories,
    ElyfBlock,
    HorizontalScroll,
    CoreSkillTab,
    FastCheckTab
  },
  data() {
    return {
      activeChildKey: "",
      childrenList: "",
      teacher: "",
      selectedChildId: "",
      selectedTab: 0,
      selectedTeachers: [],
      teacherList: [],
      totalTeacherIds: []
    };
  },
  methods: {
    ...mapActions("child", ["fetchChildrenByRoomId", "setSelectedChildId","fetchChildrenFilterByRoomId"]),
    ...mapActions("room", ["getTeachersByRoomId"]),
    setChildFromFastcheck(childId) {
      this.activeChildKey = 0;
      this.selectedChildId = childId;
      this.setSelectedChildId(childId);
    },
    firstNameOnly(fullName) {
      let name = fullName.split(" ");
      return name[0];
    },
    selectChild(val, key) {
      console.log(this.selectedTab, "this.selectedTab ");
      if (this.selectedTab == 2) {
        if (val === this.selectedChildId) {
          return;
        }
      } else {
        if (val === this.selectedChildId) {
          this.selectedChildId = "";
          val = "";
          key = "";
        }
      }

      this.activeChildKey = key;
      this.selectedChildId = val;
      this.setSelectedChildId(val);

      // this.$router.push({
      //   name: "child-profile",
      //   params: { childId: this.selectedChildId }
      // });
    },
    getAllTeachersOfRoom(roomId) {
      this.getTeachersByRoomId(roomId)
        .then(async res => {
          this.teacherList = res.data.data.filter(
            teacher => teacher._id !== this.$store.state.AppActiveUser._id
          );
          this.totalTeacherIds = this.teacherList.map(item => {
            return item._id;
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    selectAllTeachers() {
      this.selectedTeachers = JSON.parse(JSON.stringify(this.totalTeacherIds));
    },
    unSelectAllTeachers() {
      this.selectedTeachers = [];
    },
    async selectTeacher(val, key) {
      if (this.selectedTeachers.includes(val._id)) {
        this.selectedTeachers = this.selectedTeachers.filter(
          id => id !== val._id
        );
      } else {
        this.selectedTeachers.push(val._id);
      }
    }
  },

  async created() {
    this.teacher = await this.$store.state.AppActiveUser;
    await this.fetchChildrenByRoomId(this.teacher.roomId);

    this.activeChildKey = 0;
    this.selectedChildId = await this.$store.state.child.children[0]._id;
    console.log(this.selectedChildId);
    this.getAllTeachersOfRoom(this.$store.state.AppActiveUser.roomId);
  }
};
</script>

<style lang="scss" scoped>
.children-list {
  li {
    &.active {
      .con-vs-avatar.large {
        border: 3px solid #2898d5;
      }
    }
  }
}

.con-vs-avatar.large {
  width: 50px;
  height: 50px;
  margin-right: 19px;
  @media (min-width: 768px) {
    margin-right: 35px;
  }
}

.badge {
  background: #2898d5;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 13px;
  font-weight: 600;
  line-height: 28px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: -3px;
  right: 10px;
  @media (min-width: 768px) {
    right: 25px;
  }
}

.teacher-list-lead {
  @media (max-width: 576px) {
    margin-bottom: 30px;
  }

  li {
    display: inline-block;
    text-align: center;
    max-width: 100px;
    margin-right: 40px;
    vertical-align: top;
    @media (max-width: 576px) {
      max-width: 82px;
      margin-right: 10px;
    }

    &.active {
      .con-vs-avatar.large {
        border: 4px solid #289ad5;
        @media (max-width: 576px) {
          border: 2px solid #289ad5;
        }
      }
    }
  }
}
</style>
